<template>
  <v-container id="carrier-data-table" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-car-estate"
      inline
      class="px-5 py-3"
      :title="$t('carriers')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" color="primary" elevation="2" @click.stop="dialog = true"
          >Nueva Transportadora</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo para crear/editar transportadoras -->
      <v-dialog v-model="dialog" persistent max-width="700px">
        <v-card>
          <v-card-text style="margin-bottom: -50px">
            <base-subheading :subheading="formTitle" />
          </v-card-text>
          <v-stepper alt-labels flat v-model="step">
            <!-- Header del stepper -->
            <v-stepper-header>
              <!-- Paso 1 -->
              <v-stepper-step
                :rules="[() => !transportadoraErrors.length && !razonSocialErrors.length]"
                step="1"
                editable
              >
                Transportadora
                <small v-if="transportadoraErrors.length || razonSocialErrors.length"
                  >Datos incorrectos</small
                >
              </v-stepper-step>
              <v-divider></v-divider>
              <!-- Paso 2 -->
              <v-stepper-step step="2" editable>Unidades </v-stepper-step>
              <v-divider></v-divider>
              <!-- Paso 3 -->
              <v-stepper-step
                class="fixed-step-title"
                :rules="[
                  () =>
                    !apiUrlErrors.length && !writeApiKeyErrors.length && !readApiKeyErrors.length,
                ]"
                step="3"
                editable
                ><div class="step-title">API</div>
                <small
                  v-if="apiUrlErrors.length || writeApiKeyErrors.length || readApiKeyErrors.length"
                  >Datos incorrectos</small
                >
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Sección datos transportadora -->
              <v-stepper-content class="mt-4" step="1">
                <v-card elevation="0">
                  <v-row style="margin-top: -5px">
                    <!-- Transportadora -->
                    <v-col cols="4">
                      <v-text-field
                        clearable
                        dense
                        label="Nombre Transportadora"
                        required
                        v-model="$v.editedItem.transportadora.$model"
                        :error-messages="transportadoraErrors"
                        @blur="$v.editedItem.transportadora.$touch()"
                        @input="$v.editedItem.transportadora.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- Razón Social -->
                    <v-col cols="8">
                      <v-text-field
                        clearable
                        dense
                        label="Razón Social"
                        v-model="editedItem.razon_social"
                        :error-messages="razonSocialErrors"
                        @blur="$v.editedItem.razon_social.$touch()"
                        @input="$v.editedItem.razon_social.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- Representante -->
                    <v-col cols="12">
                      <v-text-field
                        clearable
                        dense
                        label="Representante"
                        v-model="editedItem.representante"
                        :error-messages="representanteErrors"
                        @blur="$v.editedItem.representante.$touch()"
                        @input="$v.editedItem.representante.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- Gerente General -->
                    <v-col cols="6">
                      <v-text-field
                        clearable
                        dense
                        label="Nombre Gerente General"
                        v-model="editedItem.gerente_general.nombre"
                      ></v-text-field>
                    </v-col>
                    <!-- Telefono Gerente General -->
                    <v-col cols="6">
                      <v-text-field
                        clearable
                        dense
                        label="Teléfono Gerente General"
                        v-model="editedItem.gerente_general.telefono"
                      ></v-text-field>
                    </v-col>
                    <!-- Gerente Operaciones -->
                    <v-col cols="6">
                      <v-text-field
                        clearable
                        dense
                        label="Nombre Gerente Operaciones"
                        v-model="editedItem.gerente_operaciones.nombre"
                      ></v-text-field>
                    </v-col>
                    <!-- Teléfono Gerente Operaciones -->
                    <v-col cols="6">
                      <v-text-field
                        clearable
                        dense
                        label="Teléfono Gerente Operaciones"
                        v-model="editedItem.gerente_operaciones.telefono"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="step = 2">Siguiente</v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- Sección Unidades -->
              <v-stepper-content class="mt-3" step="2">
                <v-card class="table-scrollable" elevation="0">
                  <v-row>
                    <v-col>
                      <v-layout justify-end>
                        <v-btn color="primary" right x-small @click="dialog2 = true"
                          >Nueva Unidad</v-btn
                        >
                      </v-layout>
                      <v-data-table
                        dense
                        :headers="headers_unidades"
                        :items="unidades"
                        hide-default-footer
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="editUnidad(item)">mdi-pencil</v-icon>
                          <v-icon small @click="deleteUnidad(item)">mdi-delete</v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="step = 1">Anterior</v-btn>
                  <v-btn color="blue darken-1" text @click="step = 3">Siguiente</v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- Sección API -->
              <v-stepper-content class="mt-3" step="3">
                <v-card elevation="0">
                  <v-row class="mt-0">
                    <!-- URL de la API -->
                    <v-col cols="12">
                      <v-text-field
                        clearable
                        dense
                        label="API URL"
                        v-model="editedItem.api_url"
                        :error-messages="apiUrlErrors"
                        @blur="$v.editedItem.api_url.$touch()"
                        @input="$v.editedItem.api_url.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- API key de escritura -->
                    <v-col>
                      <v-text-field
                        clearable
                        dense
                        label="Write API Key"
                        v-model="$v.editedItem.write_api_key.$model"
                        :append-icon="showWriteKey ? 'mdi-eye-off' : 'mdi-eye'"
                        :error-messages="writeApiKeyErrors"
                        :disabled="isApiUrlEmpty"
                        :type="showWriteKey ? 'text' : 'password'"
                        @blur="$v.editedItem.write_api_key.$touch()"
                        @click:append="showWriteKey = !showWriteKey"
                        @input="$v.editedItem.write_api_key.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- API key de lectura -->
                    <v-col cols="12">
                      <v-text-field
                        clearable
                        dense
                        label="Read API Key"
                        v-model="editedItem.read_api_key"
                        :append-icon="showReadKey ? 'mdi-eye-off' : 'mdi-eye'"
                        :disabled="isApiUrlEmpty"
                        :error-messages="readApiKeyErrors"
                        :type="showReadKey ? 'text' : 'password'"
                        @blur="$v.editedItem.read_api_key.$touch()"
                        @click:append="showReadKey = !showReadKey"
                        @input="$v.editedItem.read_api_key.$reset()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox dense label="Activo" v-model="editedItem.activo"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="step = 2">Anterior</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
                </v-card-actions>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="400px">
        <v-card>
          <v-card-text>
            <base-subheading :subheading="dialogFormTitle" />
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  label="Unidad"
                  v-model="$v.editedUnidad.unidad.$model"
                  :error-messages="unidadErrors"
                  @blur="$v.editedUnidad.unidad.$touch()"
                  @input="$v.editedUnidad.unidad.$reset()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  label="Descripción"
                  v-model="$v.editedUnidad.descripcion.$model"
                  :error-messages="descripcionErrors"
                  @blur="$v.editedUnidad.descripcion.$touch()"
                  @input="$v.editedUnidad.descripcion.$reset()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog2 = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="addUnidad">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Tabla de transportadoras  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="transportadoras"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.imagen`]="{ item }">
          <ThumborImage v-if="item.imagen" :imageUrl="item.imagen" :avatarSize="50" />
          <ThumborImage v-else />
        </template>
        <!-- Slot del boton de Activo-->
        <!-- <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template> -->
        <!-- Slot actions editar y eliminar -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, requiredIf, url } from 'vuelidate/lib/validators'

export default {
  name: 'Transportadora',
  data: () => ({
    dialog: false,
    dialog2: false,
    editedIndex: -1,
    editedUnitIndex: -1,
    isEditing: false,
    isEditingUnidad: false,
    isLoading: false,
    overlay: false,
    search: null,
    step: 1,
    showReadKey: false,
    showWriteKey: false,
    headers: [
      { text: 'Transportadora', value: 'transportadora' },
      { text: 'Nombre', align: 'start', sortable: false, value: 'razon_social' },
      { text: 'Representante', sortable: false, value: 'representante' },
      { text: 'Activo', sortable: false, value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headers_unidades: [
      { text: 'Unidad', value: 'unidad' },
      { text: 'Descripción', align: 'start', sortable: false, value: 'descripcion' },
      // { text: 'Cantidad', sortable: false, value: 'cantidad' },
      { text: '', value: 'actions', sortable: false },
    ],

    transportadoras: [],
    unidades: [],
    defaultItem: {
      transportadora: '',
      razon_social: '',
      representante: '',
      gerente_general: {
        nombre: '',
        telefono: '',
      },
      gerente_operaciones: {
        nombre: '',
        telefono: '',
      },
      flotilla: [],
      api_url: '',
      write_api_key: '',
      read_api_key: '',
      activo: false,
    },
    defaultUnidad: {
      unidad: '',
      descripcion: '',
    },
    editedItem: {
      transportadora: '',
      razon_social: '',
      representante: '',
      gerente_general: {
        nombre: '',
        telefono: '',
      },
      gerente_operaciones: {
        nombre: '',
        telefono: '',
      },
      api_url: '',
      write_api_key: '',
      read_api_key: '',
      activo: false,
    },

    editedUnidad: {
      unidad: '',
      descripcion: '',
    },
  }),
  computed: {
    /**
     * @return {string} Título para el formulario de diálogo para la adición/edición de unidades.
     */
    dialogFormTitle() {
      return this.editedUnitIndex === -1 ? 'Nueva Unidad' : 'Editar Unidad'
    },

    /**
     * @return {string} Título para el formulario principal para la adición/edición de transportadoras.
     */
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Transportadora' : 'Editar Transportadora'
    },

    /**
     * @return {boolean} Verifica si la URL de la API está vacía.
     */
    isApiUrlEmpty() {
      return !this.editedItem.api_url
    },

    /**
     * @return {boolean} Verifica si todos los campos del formulario son válidos.
     */
    isValidForm() {
      return (
        !this.$v.editedItem.transportadora.$invalid &&
        !this.$v.editedItem.razon_social.$invalid &&
        !this.$v.editedItem.representante.$invalid &&
        !this.$v.editedItem.api_url.$invalid &&
        !this.$v.editedItem.write_api_key.$invalid &&
        !this.$v.editedItem.read_api_key.$invalid
      )
    },

    /**
     * @return {boolean} Verifica si todos los campos del paso 1 son válidos.
     */
    isValidSetp1() {
      return (
        this.$v.editedItem.transportadora.$dirty &&
        this.$v.editedItem.razon_social.$dirty &&
        this.$v.editedItem.representante.$dirty &&
        !this.transportadoraErrors.length &&
        !this.razonSocialErrors.length &&
        !this.representanteErrors.length
      )
    },

    /**
     * @return {Array} Errores relacionados con el campo 'transportadora'.
     */
    transportadoraErrors() {
      const errors = []
      if (!this.$v.editedItem.transportadora.$dirty) return errors
      !this.$v.editedItem.transportadora.required && errors.push('Este campo es requerido.')
      !this.$v.editedItem.transportadora.minLength &&
        errors.push('Este campo debe de tener al menos 6 caracteres.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'razon_social'.
     */
    razonSocialErrors() {
      const errors = []
      if (!this.$v.editedItem.razon_social.$dirty) return errors
      !this.$v.editedItem.razon_social.required && errors.push('Este campo es requerido.')
      !this.$v.editedItem.razon_social.minLength &&
        errors.push('Este campo debe de tener al menos 10 caracteres.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'representante'.
     */
    representanteErrors() {
      const errors = []
      if (!this.$v.editedItem.representante.$dirty) return errors
      !this.$v.editedItem.representante.required && errors.push('Este campo es requerido.')
      !this.$v.editedItem.representante.minLength &&
        errors.push('Este campo debe de tener al menos 10 caracteres.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'api_url'.
     */
    apiUrlErrors() {
      const errors = []
      if (!this.$v.editedItem.api_url.$dirty) return errors
      !this.$v.editedItem.api_url.url && errors.push('URL inválida.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'write_api_key'.
     */
    writeApiKeyErrors() {
      const errors = []
      if (!this.$v.editedItem.write_api_key.$dirty) return errors
      !this.$v.editedItem.write_api_key.minLength &&
        errors.push('La clave de escritura API debe tener una longitud de 36.')
      !this.$v.editedItem.write_api_key.required &&
        errors.push('La clave de escritura API es requerida si se introduce una URL.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'read_api_key'.
     */
    readApiKeyErrors() {
      const errors = []
      if (!this.$v.editedItem.read_api_key.$dirty) return errors
      !this.$v.editedItem.read_api_key.minLength &&
        errors.push('La clave de lectura API debe tener una longitud de 36.')
      !this.$v.editedItem.read_api_key.required &&
        errors.push('La clave de lectura API es requerida si se introduce una URL.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'unidad'.
     */
    unidadErrors() {
      const errors = []
      if (!this.$v.editedUnidad.unidad.$dirty) return errors
      !this.$v.editedUnidad.unidad.required && errors.push('Este campo es requerido.')
      !this.$v.editedUnidad.unidad.minLength &&
        errors.push('Este campo debe de tener al menos 5 caracteres.')
      return errors
    },

    /**
     * @return {Array} Errores relacionados con el campo 'descripcion'.
     */
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedUnidad.descripcion.$dirty) return errors
      !this.$v.editedUnidad.descripcion.required && errors.push('Este campo es requerido.')
      !this.$v.editedUnidad.descripcion.minLength &&
        errors.push('Este campo debe de tener al menos 10 caracteres.')
      return errors
    },
  },
  methods: {
    /**
     * Añade una nueva unidad.
     * @example
     * @click="addUnidad";
     */
    addUnidad() {
      if (this.editedUnitIndex > -1) {
        Object.assign(this.unidades[this.editedUnitIndex], this.editedUnidad)
      } else {
        const unidad = {
          unidad: this.editedUnidad.unidad,
          descripcion: this.editedUnidad.descripcion,
          cantidad: 1,
        }
        this.unidades.push(unidad)
      }
      this.dialog2 = false
    },

    /**
     * Cierra el dialogo y reinicia sus valores.
     * @example
     * @click="close";
     *
     * this.close();
     */
    close() {
      this.dialog = false
      this.isEditing = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedUnidad = Object.assign({}, this.defaultUnidad)
        this.editedIndex = -1
        this.editedUnitIndex = -1
        this.unidades = []
        this.step = 1
      })
      this.$v.$reset()
    },

    /**
     * Elimina una unidad.
     * @example
     * @click="deleteUnidad";
     */
    deleteUnidad() {
      console.log('Eliminando Unidad')
    },

    /**
     * Edita un elemento de la lista de transportadoras.
     * @param {Object} item - El elemento que se va a editar.
     * @example
     * this.editItem({ transportadora: 'ABC', razon_social: 'XYZ' });
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.transportadoras.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.unidades = this.editedItem.flotilla
      this.dialog = true
    },

    /**
     * Edita una unidad.
     * @example
     * @click="editUnidad";
     */
    editUnidad(item) {
      this.isEditingUnidad = true
      this.editedUnitIndex = this.unidades.indexOf(item)
      this.editedUnidad = Object.assign({}, item)
      this.dialog2 = true
    },

    /**
     * Obtiene transportadoras.
     * @example
     * this.obtenerTransportadoras();
     */
    obtenerTransportadoras() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('carrier/fetchCarrier', payload)
        .then((response) => {
          this.transportadoras = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * Guarda la transportadora.
     * @example
     * @click="save";
     */
    save() {
      this.overlay = true
      this.$v.$touch()
      this.editedItem.flotilla = this.unidades
      if (this.isValidForm) {
        const body = {
          transportadora: this.editedItem.transportadora,
          razon_social: this.editedItem.razon_social,
          representante: this.editedItem.representante,
          gerente_general: this.editedItem.gerente_general,
          gerente_operaciones: this.editedItem.gerente_operaciones,
          flotilla: this.unidades,
          api_url: this.editedItem.api_url,
          write_api_key: this.editedItem.write_api_key,
          read_api_key: this.editedItem.read_api_key,
        }
        let payload = { body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('carrier/editCarrier', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              Object.assign(this.transportadoras[this.editedIndex], this.editedItem)
              this.close()
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.close()
              this.overlay = false
            })
        } else {
          this.$store
            .dispatch('carrier/addCarrier', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.transportadoras.push(response.data)
              this.close()
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data,
              })
              this.overlay = false
            })
        }
      } else {
        console.log('Invalido')
      }
    },
  },

  mounted() {
    this.obtenerTransportadoras()
  },

  validations: {
    editedItem: {
      transportadora: { required, minLength: minLength(6) },
      razon_social: { required, minLength: minLength(10) },
      representante: { required, minLength: minLength(10) },
      // gerente_general: {
      //   nombre: { required },
      //   telefono: { required, minLength: 10 },
      // },
      // gerente_operaciones: {
      //   nombre: { required },
      //   telefono: { required, minLength: 10 },
      // },
      api_url: { required: false, url },
      write_api_key: {
        required: requiredIf((editedItem) => {
          return editedItem.api_url !== ''
        }),
        minLength: minLength(36),
      },
      read_api_key: {
        required: requiredIf((editedItem) => {
          return editedItem.api_url !== ''
        }),
        minLength: minLength(36),
      },
      // Agregar validaciones adicionales según sea necesario
    },
    editedUnidad: {
      unidad: { required, minLength: minLength(5) },
      descripcion: { required, minLength: minLength(10) },
    },
  },
}
</script>

<style scoped>
.fixed-step-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-title,
.step-error {
  width: 100px; /* Ajusta este valor según tus necesidades */
  text-align: center;
}

.table-scrollable {
  max-height: 500px; /* Ajusta esto según la altura máxima que desees */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
